@import "../../shared/styles/index.scss";

.Backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	//background-color: rgba(0, 0, 0, 0.2);
	background-color: transparent;
	z-index: 999;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100vh;

	&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	> div {
		margin: 0 auto;
		padding: 5rem;
		height: 38rem;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		@include large-desktop {
			height: inherit;
			padding: 0;
		}
	}
}
