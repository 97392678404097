@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import "./shared/styles/index.scss";

body {
  margin: 0;
  padding: 0;
  background-color: $color-beige;
	-webkit-overflow-scrolling: touch;
	overflow: hidden;
	touch-action: manipulation;

	&::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;



	@include desktop {

		overflow: scroll;
	}
}
