@import "../../shared/styles/index.scss";

.Logo {
	position: fixed;
	top: 2rem;
	left: 1rem;
	max-width: 9.5rem;
	z-index: 200;

	@include mobile {
		top: 1.5rem;
	}

	@include tablet {
		top: 2rem;
		left: 1.5rem;
		max-width: 12rem;
	}

	@include large-desktop {
		left: 2rem;
	}
}
