@import "../../shared/styles/index.scss";

.Button {
	position: relative;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 400ms;
	background-color: $color-white;
	border: 1px solid $color-gray--dark;
	border-radius: 42px;
	display: inline-block;
	flex-shrink: 0;

	&.is-disabled {
		opacity: 0.5;
		cursor: default;
	}

	&.Button--circle {
		display: flex;
		align-items: center;
		border-radius: 50%;
		width: 56px;
		height: 56px;
		flex-shrink: 0;
	}

	&.Button--flat {
		border: 0;
		.Button-action {
			border-radius: 50%;
			padding: 0;
			width: 56px;
			height: 56px;
			transform: translate(0, 0);
			letter-spacing: 0;

			&:hover {
				padding: 0;
				transform: translate(0, 0);
			}
		}

		&.is-active {
			.Button-action {
				padding: 0;
			}
		}
	}

	&:not(.Button--icon) {

		.Button-action {
			> img {
				margin: 0 0 0 1rem
			}
		}
	}

	&.Button--icon {
		display: flex;
		align-items: center;
		border-radius: 50%;
		width: 56px;
		height: 56px;

		.Button-action {
			border-radius: 50%;
			padding: 0;
			width: 56px;
			height: 56px;
			transform: translate(-0.25rem, 0.25rem);

			> img {
				max-width: 1.5rem;
				max-height: 1.5rem;
			}

			&:hover {
				padding: 0;
				transform: translate(-1px, 0);
			}
		}
	}

	&.is-active {

		.Button-action {
			background-color: $color-gray;
			transform: translate(0, 0);
			padding: 22px 36px;
		}

		> img {
			position: absolute;
			top: 0;
			z-index: 100;
			right: -1.125rem;
			max-width: 2.225rem;
		}
	}

	.Button-action {
		box-sizing: border-box;
		cursor: pointer;
		text-transform: uppercase;
		transition: transform 140ms;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-white;
		border: 1px solid $color-gray--dark;
		text-decoration: none;
		text-shadow: 0 1px 0 $color-black;
		-webkit-text-stroke: 0.75px $color-black;
		letter-spacing: 1px;
		border-radius: 42px;
		@include font-roboto(1.625, $color-white, 700);
		flex-shrink: 0;
		position: relative;
		transform: translate(-0.4rem, 0.4rem);
		z-index: 100;
		padding: 21px 35px;

		&:disabled {
			pointer-events: none;
			cursor: default;
		}

		&.Button-action--small {
			font-size: 0.875rem;
		}

		@include large-desktop {
			&:hover {
				background-color: $color-red;
				transform: translate(0, 0);
				border: 0;
				padding: 22px 36px;
			}
		}

		&:active {

			background-color: $color-red;
			transform: translate(0, 0);
			border: 0;
			padding: 22px 36px;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		&.Button-action--success {
			&:hover {
				background-color: $color-green;
			}
		}
	}
}
