@import "../../shared/styles/index.scss";

.IntroModal {
	overflow-y: auto;
	z-index: 999;
	position: relative;

	&.is-visible {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
	}

	.IntroModal-header {
		text-align: center;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0.25rem 0 1.175rem;
		max-width: 17.5rem;

		@include tablet {
			max-width: 25rem;
		}

		@include desktop {
			max-width: 30rem;
		}
	}

	.IntroModal-title {
		@include font-roboto(1.825, $color-gray--dark, 700);
		margin: 0 0 1rem 0;
	}

	.IntroModal-subtitle {
		@include font-roboto(1, $color-gray--dark, 700);
		margin: 0;
	}


	.IntroModal-container {
		box-sizing: border-box;
		width: calc(100% - 3rem);
		max-width: 25rem;
		position: absolute;
		z-index: 1000;
		border: 1px solid $color-gray--dark;
		background-color: $color-white;
		border-radius: 21px;
		pointer-events: none;
		transition: opacity 500ms ease 0s;
		opacity: 0;
		padding: 1.5rem 0;
		left: 50%;
		top: 7rem;
		transform: translate(-50%, 0);
		margin: 0 0 2rem;

		&.is-visible {
			opacity: 1;
			pointer-events: auto;
		}

		@include tablet {
			max-width: 35rem;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 2rem 0 5rem;
			margin: 0;
		}
	}

	.IntroModal-close {
		margin: 0 auto;
		display: flex;
		justify-content: center;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		@include tablet {
			position: absolute;
			z-index: 1000;
			bottom: -25px;
			left: calc(11.5rem);
		}
	}

	.IntroModal-content {
		position: relative;
		padding: 0 2rem;
		box-sizing: border-box;
		text-align: center;
		margin: 0 0 2.5rem;

		@include tablet {
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		> span {
			text-align: center;
			margin: 2rem 0 1rem;
			display: block;

			@include tablet {
				margin: 0;
				transform: translateY(-2.5rem);
			}
		}

		span {
			@include font-roboto(0.875, $color-gray--dark, 700);
			text-transform: uppercase;
			opacity: 0.6;
			text-align: center;
		}
	}

	.IntroModal-separator {
		display: none;

		@include tablet {
			display: inline-block;
		}
	}

	.IntroModal-box {
		display: flex;
		flex-flow: column;
		align-items: center;

		&:first-child {
			display: none;

			@include tablet {
				display: inline-block;
			}
		}

		> img {
			margin: 0 0 1rem;

			@include tablet {
				margin: 0 0 2rem;
			}
		}

		> span {
			@include font-roboto(0.95, $color-gray--dark, 400, 1.375);
			opacity: 0.6;
			text-align: center;
			text-transform: none;
			max-width: 15rem;
			display: block;
		}
	}
}
