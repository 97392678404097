@import "../../shared/styles/index.scss";

.Modal {
  box-sizing: border-box;
	width: calc(100% - 2rem);
  position: absolute;
  z-index: 1000;
	border: 1px solid $color-gray--dark;
  background-color: $color-white;
	border-radius: 21px;
	pointer-events: none;
	max-width: 580px;
	left: 50%;
	top: 7rem;
	animation: fadeOut 600ms;
	transform: translateX(-50%);

	@include tablet {
		width: 100%;
		top: 46%;
		transform: translate(-50%, -50%);
	}

	&.is-visible {
		pointer-events: auto;
		animation: fadeIn 600ms ease-out;
	}

	@include desktop {
		top: 51%;
	}

	@include large-desktop {
		top: 53.5%;
	}

	@include xLarge-desktop {
		max-width: 620px;
	}

  .Modal-close {
	  display: none;

	  @include tablet {
		  position: absolute;
		  top: -2rem;
		  right: -2rem;
		  border: 0;
		  background-color: transparent;
		  cursor: pointer;
		  text-transform: uppercase;
		  z-index: 1000;
		  display: block;
	  }

    @include font-roboto(0.75, $color-black, 700);

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .Modal-content {
	  position: relative;
  }
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
