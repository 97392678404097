@import "../../shared/styles/index.scss";

.Login {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.Login-background {
		width: 250%;
		height: auto;
		transform: translateX(-18rem) translateY(-2rem);

		@include mobile {
			width: 150%;
			transform: translateX(-50px) translateY(50px);
		}

		@include tablet {
			transform: translateX(-200px) translateY(0);
		}

		@include desktop {
			transform: translateX(0);
			width: 100%;
			margin: 0 auto;
			max-width: 80rem;
		}
	}

	.Login-wrapper {
		position: relative;
		background-color: $color-beige;
	}

	.Login-start {
		position: absolute;
		bottom: 2.5rem;
		left: 20%;
		z-index: 1000;

		@include mobile {
			top: 220px;
			left:60px;
		}

		@include tablet {
			top: 380px;
			left: 100px;
		}

		@include desktop {
			top: 350px;
			left: 240px;
		}

		@include large-desktop {
			top: 400px;
			left: 300px;
		}

		@include xLarge-desktop {
			top: 400px;
			left: 310px;
		}
	}

	.Login-title {
		text-shadow: 0 2px 0 $color-black;
		-webkit-text-stroke: 1px $color-black;
		max-width: 16rem;
		@include font-roboto(1.75, $color-white, 700);

		@include tablet {
			font-size: 2rem;
		}

		@include xLarge-desktop {
			max-width: 20rem;
		}
	}
}

