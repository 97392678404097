@import "../../shared/styles/index.scss";

.Marker {
	position: absolute;
	width: 2.25rem;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	z-index: 100;
	border: 0;
	cursor: pointer;
	transition: all 400ms;
	pointer-events: none;

	@include tablet {
		pointer-events: auto;
	}

	@include desktop {
		height: 3.125rem;

		@supports (-ms-ime-align:auto) {
			height: 8rem;
		}
	}

	> img {
		width: 100%;
	}

	&:hover {
		&:not(:disabled) {
			animation: mover 600ms infinite alternate;
		}
	}

	&:disabled {
		cursor: default;
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}

	&:nth-of-type(1) {
		bottom: 8.5rem;
		right: 25.5rem;
	}

	&:nth-of-type(2) {
		bottom: 19.375rem;
		right: 16.675rem;
	}

	&:nth-of-type(3) {
		top: 13.75rem;
		right: 21.25rem;
	}

	&:nth-of-type(4) {
		top: 8.5rem;
		right: 31.75rem;
	}

	&:nth-of-type(5) {
		top: 17.25rem;
		right: 46.75rem;
	}

	&:nth-of-type(6) {
		top: 22.75rem;
		right: 47.5rem;
	}

	&:nth-of-type(7) {
		top: 25.875rem;
		right: 42.125rem;
	}

	&:nth-of-type(8) {
		top: 21.5rem;
		right: 34.75rem;
	}

	&:nth-of-type(9) {
		top: 14.5rem;
		right: 37.75rem;
	}

	&:nth-of-type(10) {
		top: 17.125rem;
		right: 26.375rem;
	}

	@include mobile {
		&:nth-of-type(1) {
			bottom: 8.625rem;
			right: 34.675rem;
		}

		&:nth-of-type(2) {
			bottom: 21.25rem;
			right: 24.125rem;
		}

		&:nth-of-type(3) {
			top: 17.75rem;
			right: 29.75rem;
		}

		&:nth-of-type(4) {
			top: 11.625rem;
			right: 42rem;
		}

		&:nth-of-type(5) {
			top: 21.75rem;
			right: 59.375rem;
		}

		&:nth-of-type(6) {
			top: 28rem;
			right: 60.125rem;
		}

		&:nth-of-type(7) {
			top: 31.625rem;
			right: 53.875rem;
		}

		&:nth-of-type(8) {
			top: 26.75rem;
			right: 45.5rem;
		}

		&:nth-of-type(9) {
			top: 18.625rem;
			right: 48.875rem;
		}

		&:nth-of-type(10) {
			top: 21.625rem;
			right: 35.625rem;
		}
	}

	@include tablet {
		&:nth-of-type(1) {
			bottom: 10.5rem;
			right: 42.375rem;
		}

		&:nth-of-type(2) {
			bottom: 25.875rem;
			right: 29.625rem;
		}

		&:nth-of-type(3) {
			top: 22.25rem;
			right: 36.375rem;
		}

		&:nth-of-type(4) {
			top: 14.75rem;
			right: 51.25rem;
		}

		&:nth-of-type(5) {
			top: 27rem;
			right: 72.375rem;
		}

		&:nth-of-type(6) {
			top: 34.675rem;
			right: 73.375rem;
		}

		&:nth-of-type(7) {
			top: 39rem;
			right: 65.625rem;
		}

		&:nth-of-type(8) {
			top: 33.125rem;
			right: 55.5rem;
		}

		&:nth-of-type(9) {
			top: 23.25rem;
			right: 59.75rem;
		}

		&:nth-of-type(10) {
			top: 27rem;
			right: 43.5rem;
		}
	}

	@include desktop {
		&:nth-of-type(1) {
			bottom: 6.75rem;
			right: 25rem;
		}

		&:nth-of-type(2) {
			top: 17.25rem;
			right: 17.375rem;
		}

		&:nth-of-type(3) {
			top: 12.125rem;
			right: 21.375rem;
		}

		&:nth-of-type(4) {
			top: 7.625rem;
			right: 30.375rem;
		}

		&:nth-of-type(5) {
			top: 15rem;
			right: 43rem;
		}

		&:nth-of-type(6) {
			top: 19.5rem;
			right: 43.625rem;
		}

		&:nth-of-type(7) {
			top: 22.25rem;
			right: 39rem;
		}

		&:nth-of-type(8) {
			top: 18.75rem;
			right: 32.875rem;
		}

		&:nth-of-type(9) {
			top: 12.75rem;
			right: 35.425rem
		}

		&:nth-of-type(10) {
			top: 15rem;
			right: 25.75rem;
		}
	}

	@include large-desktop {
		&:nth-of-type(1) {
			bottom: 8.25rem;
			right: 31.575rem;
		}

		&:nth-of-type(2) {
			top: 22.375rem;
			right: 21.925rem;
		}

		&:nth-of-type(3) {
			top: 15.875rem;
			right: 27rem;
		}

		&:nth-of-type(4) {
			top: 10.375rem;
			right: 38.25rem;
		}

		&:nth-of-type(5) {
			top: 19.5rem;
			right: 54.125rem;
		}

		&:nth-of-type(6) {
			top: 25.25rem;
			right: 54.875rem;
		}

		&:nth-of-type(7) {
			top: 28.675rem;
			right: 49.125rem;
		}

		&:nth-of-type(8) {
			top: 24.125rem;
			right: 41.5rem;
		}

		&:nth-of-type(9) {
			top: 16.75rem;
			right: 44.625rem;
		}

		&:nth-of-type(10) {
			top: 19.5rem;
			right: 32.5rem;
		}
	}

	@include xLarge-desktop {
		&:nth-of-type(1) {
			bottom: 9.25rem;
			right: 35.675rem;
		}

		&:nth-of-type(2) {
			top: 25.675rem;
			right: 24.875rem;
		}

		&:nth-of-type(3) {
			top: 18.375rem;
			right: 30.625rem;
		}

		&:nth-of-type(4) {
			top: 12rem;
			right: 43.125rem;
		}

		&:nth-of-type(5) {
			top: 22.375rem;
			right: 61rem;
		}

		&:nth-of-type(6) {
			top: 28.75rem;
			right: 61.875rem;
		}

		&:nth-of-type(7) {
			top: 32.5rem;
			right: 55.375rem;
		}

		&:nth-of-type(8) {
			top: 27.675rem;
			right: 46.875rem;
		}

		&:nth-of-type(9) {
			top: 19.25rem;
			right: 50.375rem;
		}

		&:nth-of-type(10) {
			top: 22.375rem;
			right: 36.75rem;
		}
	}
}

@keyframes mover {
	0% { transform: translateY(0); }
	100% { transform: translateY(-0.375rem); }
}
