@import "../../shared/styles/index.scss";

.Toast {
	position: relative;
	background-color: $color-red;
	display: flex;
	align-items: center;
	padding: 0.75rem;
	justify-content: center;
	text-transform: uppercase;
	white-space: nowrap;
	border: 1px solid $color-gray--dark;

	&:after,
	&:before {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(226, 79, 67, 0);
		border-left-color: $color-red;
		border-width: 10px;
		margin-top: -10px;
	}
	&:before {
		border-color: rgba(54, 62, 65, 0);
		border-left-color: $color-gray--dark;
		border-width: 11px;
		margin-top: -11px;
	}

	> span {
		@include font-roboto(0.875, $color-white, 400);
	}

	> img {
		margin: 0 0.5rem 0 0;
		width: 1.5rem;
	}

	> strong {
		font-weight: bold;
	}
}
