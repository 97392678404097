/* Fonts */
$font-title: 'Roboto',
Helvetica,
Arial,
sans-serif;

@mixin font-roboto($size: false,
$colour: false,
$weight: false,
$lh: false,
$ls: false
) {
  font-family: $font-title;

  @if $size {
    font-size: $size + rem;
  }

  @if $colour {
    color: $colour;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh + rem;
  }

  @if $ls {
    letter-spacing: $ls + rem;
  }
}
