@import "../../shared/styles/index.scss";

.TaskSelectorModal {
	overflow-y: auto;
	z-index: 999;
	position: relative;

	&.is-visible {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
	}

	.TaskSelectorModal-container {
		box-sizing: border-box;
		width: calc(100% - 3rem);
		max-width: 25rem;
		position: absolute;
		z-index: 1000;
		border: 1px solid $color-gray--dark;
		background-color: $color-white;
		border-radius: 21px;
		pointer-events: none;
		transition: opacity 500ms ease 0s;
		opacity: 0;
		padding: 2rem 0;
		left: 50%;
		top: 7rem;
		transform: translate(-50%, 0);
		margin: 0 0 2rem;

		&.is-visible {
			opacity: 1;
			pointer-events: auto;
		}

		@include tablet {
			max-width: 35rem;
		}

		@include desktop {
			top: 3rem;
			margin: 0 0 5rem;
		}

		@include large-desktop {
			max-width: 550px;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 2rem 0 5rem;
			margin: 0;
		}
	}

	.TaskSelectorModal-close {
		margin: 0 auto;
		display: flex;
		justify-content: center;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		@include large-desktop {
			position: absolute;
			z-index: 1000;
			bottom: -25px;
			left: calc(275px - 6rem);
		}
	}

	.TaskSelectorModal-content {
		position: relative;
	}
}
