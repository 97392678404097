@import "../../shared/styles/index.scss";

.TrailerModal {
	overflow-y: auto;
	z-index: 9999;
	position: relative;

	&.is-visible {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		animation: fadeIn .5s;
	}

	.TrailerModal-header {
		text-align: center;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0.25rem 0 1.175rem;
		max-width: 17.5rem;

		@include tablet {
			max-width: 25rem;
		}

		@include desktop {
			max-width: 30rem;
		}
	}

	.TrailerModal-title {
		@include font-roboto(1.825, $color-gray--dark, 700);
		margin: 0;
	}

	.TrailerModal-container {
		box-sizing: border-box;
		width: calc(100% - 3rem);
		max-width: 25rem;
		position: absolute;
		z-index: 1000;
		border: 1px solid $color-gray--dark;
		background-color: $color-white;
		border-radius: 21px;
		pointer-events: none;
		transition: opacity 500ms ease 0s;
		opacity: 0;
		padding: 1.5rem 0;
		left: 50%;
		top: 7rem;
		transform: translate(-50%, 0);
		margin: 0 0 2rem;

		&.is-visible {
			opacity: 1;
			pointer-events: auto;
		}

		@include tablet {
			max-width: 35rem;
			top: 50%;
			transform: translate(-50%, -50%);
			padding: 2rem 0 5rem;
			margin: 0;
		}
	}

	.TrailerModal-close {
		margin: 0 auto;
		display: flex;
		justify-content: center;

		&:focus {
			outline: 0;
			box-shadow: none;
		}

		@include tablet {
			position: absolute;
			z-index: 1000;
			bottom: -25px;
			left: calc(11.5rem);
		}
	}

	.TrailerModal-content {
		height: 10.5rem;
		box-sizing: border-box;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 1rem 2rem;

		@include tablet {
			margin: 0;
			height: 15.5rem;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.TrailerModal-player {
		//width: calc(100% - 4.75rem) !important;
		margin: 0 auto;
		> div {
			background-color: $color-white !important;
		}

		iframe {
			background-color: $color-white;
			animation: hackPlayer 5s linear;
		}
		z-index: 9990;

		&.TrailerModal-player--over {
			z-index: 9999;
		}
	}

	.TrailerModal-poster {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9998;
		visibility: hidden;
		background-color: $color-white;

		&.is-visible {
			visibility: visible;

			&:hover {
				.TrailerModal-controls {
					opacity: 1;
				}
			}
		}
	}

	.TrailerModal-background {
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: all 300ms ease-in-out;

		&.is-visible {
			opacity: 1;
		}
	}

	.TrailerModal-controls {
		transition: opacity 350ms ease-in-out;
		opacity: 1;

		@include large-desktop {
			//opacity: 0;
		}
	}
}

@keyframes fadeIn {
	0% { opacity: 0 }
	100% { opacity: 1 }
}
