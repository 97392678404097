@import "../../shared/styles/index.scss";

.Task {
	position: relative;

	.Task-marker {
		width: 3rem;
		margin: 0 auto;
		position: absolute;
		top: -50px;
		left: calc(50% - 1.5rem);

		@include tablet {
			left: 40px;
			width: 4.375rem;
			height: auto;
		}

		@include xLarge-desktop {
			width: 4.75rem;
		}
	}

	.Task-header {
		padding: 3.5rem 0 1rem;
		box-sizing: border-box;
		margin: 0 auto;
		border-bottom: 1px solid rgba(#464a4a, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 7rem;

		@include tablet {
			padding: 2.25rem 2.25rem 2rem;
			max-width: calc(100% - 4rem);
		}

		@include xLarge-desktop {
			min-height: 9rem;
		}
	}

	.Task-title {
		@include font-roboto(1.5, $color-gray--dark, 700);
		max-width: 18rem;
		margin: 0;
		text-align: center;

		@include tablet {
			max-width: 20.5rem;
		}

		@include xLarge-desktop {
			font-size: 1.675rem;
			max-width: 23rem;
		}
	}

	.Task-container {
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		margin: 0 auto;
		padding: 2rem 0;

		@include xLarge-desktop {
			padding: 2.5rem 0;
		}
	}

	.Task-arrow {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		justify-content: center;
		min-height: 3.875rem;

		&.Task-arrow--up {
			margin: 0;

			@include mobile {
				margin: 0 0 2rem;
			}
		}

		&.Task-arrow--down {
			margin: 0;

			@include mobile {
				margin: 2rem 0 0;
			}
		}
	}

	.Task-content {
		height: 15.5rem;
		box-sizing: border-box;
		margin: 0.275rem 0;
		transform: translateX(1rem);
		max-width: calc(100% - 3rem);
		position: relative;
		display: flex;
		align-items: center;
		overflow-y: auto;

		@include tablet {
			max-width: calc(100% - 4.375rem);
			transform: translateX(2.125rem);
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.Task-player {
		//width: calc(100% - 4.75rem) !important;
		margin: 0 auto;
		iframe {
			background-color: $color-white;
			animation: hackPlayer 5s linear;
		}
		z-index: 9990;

		&.Task-player--over {
			z-index: 9999;
		}
	}

	.Task-poster {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9998;
		visibility: hidden;
		background-color: $color-white;

		&.is-visible {
			visibility: visible;

			&:hover {
				.Task-controls {
					opacity: 1;
				}
			}
		}
	}

	.Task-background {
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: all 300ms ease-in-out;

		&.is-visible {
			opacity: 1;
		}
	}

	.Task-controls {
		transition: opacity 350ms ease-in-out;
		opacity: 1;

		@include large-desktop {
			//opacity: 0;
		}
	}

	.Task-text {
		box-sizing: border-box;
		@include font-roboto(0.925, $color-gray--light, 400, 1.5);
		padding: 1rem 1.5rem 1rem 2rem;
		border: 1px solid $color-gray--dark;
		background-color: $color-gray;
		border-radius: 36px 0 0 36px;
		margin: 2rem 0 0;
		height: 11.5rem;
		//scroll-snap-type: y proximity;
		overflow-y: auto;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 0.5rem;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 0;
			background-color: $color-gray--light;
			-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
		}

		@include mobile {
			height: 14.25rem;
			margin: .125rem 0 0;
			padding: 1rem 1rem 1rem 2rem ;
		}

		@include tablet {
			margin: 0.875rem 0 0;
		}

		@include xLarge-desktop {
			margin: 0.675rem 0 0;
		}

		> h4 {
			@include font-roboto(1.125, $color-gray--dark, 700);
			text-align: center;
			margin: 0 0 1.125rem;
		}

		h5 {
			@include font-roboto(0.925, $color-gray--light, 700, 1.5);
			margin: 0 0 .25rem;
		}

		p {
			margin: 0 0 1rem 0;
		}

		ul {
			margin: 0;
			padding: 0 0 0 1rem;
		}

		a {
			@include font-roboto(0.925, $color-black--light, 400, 1.5);
		}
	}

	.Task-documents {
		box-sizing: border-box;
		@include font-roboto(1.125, $color-gray--medium, 400);
		padding: 0.5rem 0;
		overflow-y: auto;
		min-height: 14.5rem;
		display: flex;
		align-items: center;
		position: relative;

		.Task-docImage {
			max-width: 3rem;
			position: absolute;
			top: 1.25rem;
			left: 0.5rem;
			z-index: 100;

			@include tablet {
				max-width: 4rem;
				top: 0;
			}

			&.Task-docImage--short {
				top: 2.5rem;
			}

			> img {
				width: 100%;
			}
		}
	}

	.Task-pages {
		position: absolute;
		right: -2.925rem;
		top: 6rem;
		height: 12.5rem;
		padding: 1.75rem 1.5rem 1.75rem 0.575rem;
		background-color: $color-white;
		border: 1px solid $color-gray--dark;
		border-radius: 28px;
		width: 2.75rem;

		@include mobile {
			height: 17rem;
			width: 3rem;
		}
	}

	.Task-tower {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		height: 100%;
		z-index: 200;
	}

	.Task-page {
		@include font-roboto(0.525, $color-white, 400);
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $color-gray--dark;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		cursor: pointer;
		transition: all 300ms;
		z-index: 200;
		background-color: $color-white;

		&.is-active {
			text-shadow: 0 2px 0 $color-black;
			-webkit-text-stroke: 0.25px $color-black;
			background-color: $color-red;
			border-color: $color-red;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.Task-progress {
		position: absolute;
		height: calc(100% - 4.25rem);
		width: 2px;
		top: 2.5rem;
		left: 1rem;
		@include background-opacity($color-gray--medium, 0.3);
	}

	.Task-fluid {
		width: 2px;
		background-color: $color-red;
	}
}

@keyframes hackPlayer {
	0% { visibility: hidden }
	100% { visibility: visible }
}
