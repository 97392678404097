@import "../../shared/styles/index.scss";

.DocsList {
	position: relative;
	background-color: $color-gray;
	width: 100%;
	padding: 0.75rem 1rem 0.75rem 1rem;
	box-sizing: border-box;
	max-height: 11rem;
	margin: 1rem 0 0;
	overflow-y: auto;

	@include tablet {
		max-height: inherit;
		margin: 0;
		padding: 0.75rem 2rem 0.75rem 1rem;
	}

	.DocsList-title {
		@include font-roboto(1, $color-gray--dark, 400);
		margin: 0 0 0.5rem;
		padding: 0 0 0 3.75rem;
		max-width: calc(100% - 3.75rem);

		@include tablet {
			padding: 0 0 0 5.5rem;
			max-width: calc(100% - 5.5rem);
		}
	}

	.DocsList-items {
		list-style: none;
		margin: 0;
		padding: 0 0 0 3.75rem;
		width: 100%;
		scroll-snap-type: y proximity;
		overflow-y: scroll;
		max-width: calc(100% - 3.75rem);

		@include tablet {
			overflow: hidden;
			padding: 0 0 0 5.5rem;
			max-width: calc(100% - 5.5rem);
		}
	}

	.DocsList-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-flow: column;
	}

	.DocsList-item {
		margin: 0 0 0.5rem 0;
		display: flex;
		align-items: center;
		width: 100%;
		@include font-roboto(0.75, $color-gray--dark, 400);
		scroll-snap-align: start;

		> strong {
			font-weight: 700;
			margin: 0 0.25rem 0 0;
		}

		> a {
			display: inline-flex;
			margin: 0 0 0 auto;
			@include font-roboto(0.75, $color-red, 700);
			text-transform: uppercase;
			text-decoration: none;
			transition: opacity 300ms;

			&:hover {
				opacity: 0.8;
			}
		}

		&:last-child {
			margin: 0;
		}
	}
}
