@import "../../shared/styles/index.scss";

.MapMenuButton {
	position: fixed;
	top: 0.75rem;
	right: 1rem;
	z-index: 9999;
	opacity: 1;
	transition: opacity 800ms ease-in-out;

	@include tablet {
		top: 1.5rem;
	}

	@include large-desktop {
		right: 2rem;
	}

	&.is-hidden {
		opacity: 0;
		z-index: 1;
		transition: opacity 250ms ease-out;
	}
}
