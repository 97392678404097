@import "../../shared/styles/index.scss";

.LanguageSwitcher {
	position: fixed;
	bottom: 2rem;
	right: 1.5rem;
	z-index: 100;
	//display: none;

	@include tablet {
		bottom: 2.5rem;
		right: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@include large-desktop {
		right: 2rem;
	}

	&.LanguageSwitcher--medium {
		.LanguageSwitcher-button {
			font-size: 1rem;
		}
	}

	.LanguageSwitcher-button {
		@include font-roboto(1, $color-white, 400);
		padding: 0;
		margin: 0 12px 0 0;
		border: 0;
		background-color: transparent;
		transition: opacity 300ms;
		cursor: pointer;
		opacity: 0.6;

		&:last-child {
			margin: 0;
		}

		&:hover {
			opacity: 1;
		}

		&.is-active {
			text-decoration: underline;
			cursor: default;
			opacity: 1;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}
}