// Colors
$color-white: #fff;
$color-black: #000;
$color-black--light: #262729;
$color-gray: #f7f7f7;
$color-gray--medium: #464a4a;
$color-gray--dark: #363e41;
$color-gray--light: rgba(54,62,65, 0.55);
$color-beige: #CCB498;
$color-red: #e24f43;
$color-green: #ABC115;
