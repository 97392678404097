@import "../../shared/styles/index.scss";

.TaskSelector {
	position: relative;

	.TaskSelector-header {
		text-align: center;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0.875rem 0 1.175rem;
		max-width: 17.5rem;

		@include tablet {
			max-width: 25rem;
		}

		@include desktop {
			max-width: 30rem;
		}
	}

	.TaskSelector-title {
		@include font-roboto(1.825, $color-gray--dark, 700);
		margin: 0 0 1rem 0;
	}

	.TaskSelector-subtitle {
		@include font-roboto(1, $color-gray--dark, 700);
		opacity: 0.3;
		margin: 0 auto 1rem;
	}

	.TaskSelector-content {
		@include font-roboto(1.6, $color-red, 700);
		margin: 2rem 0 3rem;

		@include large-desktop {
			margin: 0;
		}
	}

	.TaskSelector-firstRow,
	.TaskSelector-secondRow {
		width: 100%;
		box-sizing: border-box;
		padding: 0 1.25rem;

		> div {
			margin: 0 0 1.25rem 0;

			&:last-child {
				margin: 0;
			}
		}

		@include tablet {
			padding: 0 1.75rem;

			> div {
				margin: 0 0 1.5rem 0;
			}
		}

		@include large-desktop {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 2.5rem 2rem;

			> div {
				margin: 0;
			}
		}
	}

	.TaskSelector-box {
		opacity: 0;
		height: 1.25rem;

		@include large-desktop {
			display: block;
			position: relative;
			height: 4.5rem;
			background-color: $color-gray;
			padding: 0 2.5rem 0 0;
			z-index: 1000;
			opacity: 1;
		}
	}

	.TaskSelector-description {
		opacity: 0;
		transition: opacity 0ms ease-out;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		&.is-visible {
			opacity: 1;
			transition: opacity 300ms;
		}
	}

	.TaskSelector-question {
		@include font-roboto(0.875, $color-gray--dark, 700);
	}

	.TaskSelector-image {
		max-width: 12rem;
		width: 100%;
		display: flex;
		align-items: center;
		pointer-events: none;

		> img {
			width: 100%;
		}
	}

	.TaskSelector-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: -2rem;
	}

	.TaskSelector-button {
		display: flex;
		align-items: center;

		> div {
			margin: 0 1.75rem 0 0;

			@include large-desktop {
				margin: 0;
			}
		}

		> span {
			@include font-roboto(1.125, $color-gray--dark, 700);

			@include large-desktop {
				display: none;
			}
		}
	}

	.TaskSelector-taskQuestion {
		display: none;
		@include large-desktop {
			display: block;
		}
	}

	.TaskSelector-taskTitle {
		@include large-desktop {
			display: none;
		}
	}

	.TaskSelector-id {
		display: inline-block;
		margin: 0 0.125rem;
	}

	.TaskSelector-showTrailer {
		text-decoration: underline;
		@include font-roboto(1, $color-gray--dark, 400);
		cursor: pointer;
		background-color: transparent;
		border: 0;
		transition: opacity 250ms;

		&:hover {
			opacity: 0.8;
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}
}
