@import "../../shared/styles/index.scss";

.ThankYou {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fadeIn 2000ms;

	.ThankYou-background {
		height: 100vh;
	}

	.ThankYou-wrapper {
		position: relative;
		background-color: $color-beige;
	}

	.ThankYou-restart {
		position: absolute;
		bottom: 10rem;
		left: 35%;
		z-index: 1000;

		@include mobile {
			bottom: 3rem;
			left: 3rem;
		}

		@include tablet {
			top: 40rem;
			left: 38rem;
		}

		@include desktop {
			top: 22rem;
			left: 18rem;
		}

		@include large-desktop {
			top: 300px;
			left: 200px;
		}

		@include xLarge-desktop {
			top: 400px;
			left: 310px;
		}
	}

	.ThankYou-title {
		text-shadow: 0 2px 0 $color-black;
		-webkit-text-stroke: 1px $color-black;
		max-width: 20rem;
		@include font-roboto(1.75, $color-white, 700);

		@include tablet {
			font-size: 2rem;
			max-width: 25rem;
		}
	}
}

@keyframes fadeIn {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

