@import "../../shared/styles/index.scss";

.Map {
	background-color: $color-beige;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	display: flex;
	place-content: center;
	place-items: center;
	overflow: hidden;

	.Map-AdminButtonContainer{
		position: fixed;
		bottom: 2rem;
		left: 2rem;
		z-index: 10000;
	}

	&.Map--withZoom {
		overflow: visible;
	}

	@include desktop {
		width: 100%;
		height: 42.5rem;
	}

	@include large-desktop {
		height: 50rem;
		width: 80rem;
	}

	@include xLarge-desktop {
		height: 55rem;
		width: 90rem;
	}

	@include fullHD-desktop {
		height: 60rem;
	}

	.Map-menu {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		position: fixed;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		z-index: 1000;

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.Map-wrapper {
		background-color: $color-beige;
		border: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		transition: transform .5s ease-out;
		animation: fadeIn 1500ms;

		.Map-background {
			background-color: $color-beige;
			transform: translate(4rem, -1rem);
			width: 1200px;
			height: auto;

			@include mobile {
				width: 1400px;
				transform: translate(0, 0);
			}

			@include tablet {
				margin: 0;
				width: 1700px;
			}

			@include desktop {
				width: 100%;
			}
		}

		&.is-highlighted {
			transform: scale(3.5);
		}

		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

	.Map-toast {
		opacity: 0;

		@include mobile {
			position: fixed;
			top: 1.25rem;
			right: 6.5rem;
			transition: opacity 300ms;

			&.is-visible {
				opacity: 1;
				transition: opacity 500ms;
			}
		}

		@include tablet {
			top: 2rem;
			right: 8rem;
		}
	}

	.Map-offset {
		width: 10rem;
		height: 10rem;
		border: 3px solid black;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none;
	}

	.Map-mobileClose {
		opacity: 0;

		&.is-visible {
			opacity: 1;
			animation: fadeIn 600ms;
		}

		@include tablet {
			display: none;
		}
	}
}

@keyframes fadeIn {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

