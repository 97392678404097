@import "../../shared/styles/index.scss";

.Welcome {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.Welcome-background {
		width: 250%;
		height: auto;
		transform: translateX(-18rem) translateY(-2rem);

		@include mobile {
			width: 150%;
			transform: translateX(-50px) translateY(50px);
		}

		@include tablet {
			transform: translateX(-200px) translateY(0);
		}

		@include desktop {
			transform: translateX(0);
			width: 100%;
			margin: 0 auto;
			max-width: 80rem;
		}
	}

	.Welcome-wrapper {
		position: relative;
		background-color: $color-beige;
	}

	.Welcome-start {
		position: absolute;
		bottom: 5rem;
		left: 1rem;
		z-index: 1000;

		@include mobile {
			bottom: 10rem;
		}

		@include tablet {
			top: 24rem;
			left: 6rem;
		}

		@include desktop {
			top: 22rem;
			left: 15rem;
		}

		@include large-desktop {
			top: 25rem;
			left: 19rem;
		}

		@include xLarge-desktop {
			top: 25rem;
			left: 20rem;
		}
	}

	.Welcome-title {
		text-shadow: 0 2px 0 $color-black;
		-webkit-text-stroke: 1px $color-black;
		max-width: 16rem;
		@include font-roboto(1.75, $color-white, 700);

		@include tablet {
			font-size: 2rem;
		}

		@include xLarge-desktop {
			max-width: 20rem;
		}
	}
}

